export const AUTH_ENDPOINT = 'oauth/token';
export const REVOKE_ENDPOINT = 'oauth/revoke';
export const USERS_ENDPOINT = 'api/v1/users';
export const FORGOT_PASSWORD_ENDPOINT = 'users/password';
export const ROLES_ENDPOINT = 'api/v1/roles';
export const BACKEND_TABLES_PAY_METHOD = 'api/v1/pay_methods';
export const BACKEND_TABLES_PROVIDER_CATEGORY = 'api/v1/provider_categories';
export const BACKEND_TABLES_PROVIDER_TITLE = 'api/v1/provider_titles';
export const BACKEND_TABLES_FACILITY_TYPES = 'api/v1/facility_types';
export const BACKEND_TABLES_PAYROLL_GROUPS = 'api/v1/payroll_groups';
export const SYSTEM_AUDIT_ENDPOINT = 'api/v1/users/audits';
export const ENTITIES_ENDPOINT = 'api/v1/entities';
export const PROVIDERS_ENDPOINT = 'api/v1/providers';
export const PROVIDERS_ENDPOINT_BLOB = 'api/v1/providers/export';
export const CHECK_PROVIDERS_ENDPOINT = 'api/v1/providers/provider_valid';
export const PROVIDER_TITLES_ENDPOINT = 'api/v1/provider_titles';
export const PROVIDER_CATEGORY_ENDPOINT = 'api/v1/provider_categories';
export const PAYROLL_INFORMATION_ENDPOINT = 'api/v1/payroll_informations';
export const PAY_METHOD_LIST = 'api/v1/pay_methods';
export const FACILITIES_ENDPOINT = 'api/v1/facilities';
export const FACILITIES_TYPES_ENDPOINT = 'api/v1/facility_types';
export const PROVIDERS_FACILITIES_ENDPOINT = 'api/v1/provider_facility';
export const EARNING_CODES_ENDPOINT = 'api/v1/earning_codes';
export const PROVIDER_RATES_ENDPOINT = 'api/v1/provider_rates';
export const PAYROLL_GROUP_ENTITIES = 'api/v1/payroll_entities';
export const PAYROLL_LOOKER_IMPORT = 'api/v1/looker_imports';
export const PAYROLL_LOOKER_IMPORT_LOCK = 'api/v1/looker_imports/lock';
export const ADP_EXPORT_ENDPOINT = 'api/v1/adp_exports';
export const SEND_TO_ADP_ENDPOINT = 'api/v1/provider_data/send_to_adp';
export const NOTES_ENDPOINT = 'api/v1/notes';
export const PAYMENT_ENTITY_ENDPOINT = 'api/v1/payment_entities';
export const PROVIDER_DATA_ENDPOINT = 'api/v1/provider_data';
export const PROVIDER_UNPAID_DATA_ADJUSTMENT_ENDPOINT = 'api/v1/provider_data/adjustments'
export const PROVIDER_DATA_UC_ENDPOINT = 'api/v1/provider_data/provider_data_uc';
export const EMAIL_QUEUE_ENDPOINT = 'api/v1/emails';
export const SEND_MAIL_ENDPOINT = 'api/v1/emails/send_mail';
export const EMAIL_TEMPLATES_ENDPOINT = 'api/v1/email_templates';
export const GROSS_BILLING_REPORTS_ENDPOINT = 'api/v1/provider_data/gross_billing';
export const FACILITY_UNITS_REPORTS_ENDPOINT = 'api/v1/provider_data/facility_unit';
export const RAW_DATA_ENDPOINT = 'api/v1/provider_data/search';
export const ACTIVE_ANALYSIS_ENDPOINT = 'api/v1/provider_data/active_analysis';
export const ACTIVE_ANALYSIS_EMAIL_ENDPOINT = 'api/v1/providers/analysis_email';
export const PAYROLL_PERIOD_ENDPOINT = 'api/v1/payroll_periods';
export const COMING_PAST_PAYROLL_PERIOD_ENDPOINT = 'api/v1/payroll_periods/coming_past_periods';
export const CLOSE_AND_START_NEW_PAYROLL_PERIOD_ENDPOINT = 'api/v1/payroll_periods/close_and_start_new';
