import React, {useEffect, useRef, useState} from 'react';

import TableWraper from 'components/common/table-wrapper';
import CustomToast from 'components/common/custom-toast';

import {getActionButtonProps, getPaginatedItems, isAdministrator, isPayRoll} from 'utils/common';
import {DEBOUNCE_DELAY} from 'utils/common';
import {initialMetaForTable} from 'constants/common';
import {CSVDownload, CSVLink} from 'react-csv';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import {sendToAdp} from 'containers/adp-export/api';
import moment from 'moment';

let timeout;
const headers = [
  {label: 'Co Code', key: 'co_code'},
  {label: 'File Number', key: 'adp_number'},
  {label: 'Reg Earnings', key: 'reg_earnings'},
  {label: 'Reg Hours', key: 'reg_hours'},
];

const RecordsListing = ({records, searchValues}) => {
  const [allListSelected, setAllListSelected] = useState(false);
  const [data, setData] = useState(records);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(records.length);
  const [csvData, setCsvData] = useState([]);
  const exportRef = useRef(null);
  const handleSubmitToAdp = async () => {
    let {start_date, end_date, payroll_group_id} = searchValues;
    start_date = moment(start_date).format('YYYY-MM-DD');
    end_date = moment(end_date).format('YYYY-MM-DD');
    const result = await sendToAdp({start_date, end_date, payroll_group_id});
  };
  function fetchData() {
    const result = getPaginatedItems(records, meta.page, meta.perPage)?.data?.map((item, index) => ({
      ...item,
      isSelected: allListSelected,
      id: index,
    }));
    setData(result);
    setLoading(false);
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setLoading(true);
    fetchData();
  }, [refreshPage]);
  useEffect(() => {
    setCsvData(data.filter(item => item.isSelected));
  }, [data]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setData(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setData(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };

  const handleExport = done => {
    exportRef.current.link.click();
    if (csvData.length) {
      done(true);
    } else {
      toast(<CustomToast variant={alertTypes.DANGER} message={'Please Select Record!'} />);
      done(false);
    }
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll()) && [
            ...getActionButtonProps('Export', () => {
              exportRef.current.link.click();
            }),
            {
              label: 'Mark Paid',
              handleClick: handleSubmitToAdp,
              classes: 'secondary-btn-small record-btn',
            },
          ]
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Co Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">File Number</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Reg Earnings</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Reg Hours</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>

                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.co_code || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.adp_number || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.provider_name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.reg_earnings || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.reg_hours || ''}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
      <CSVLink
        ref={exportRef}
        data={csvData}
        headers={headers}
        asyncOnClick={true}
        onClick={(event, done) => {
          handleExport(done);
        }}
        filename={'adp-export.csv'}
        className="btn btn-primary opacity-0 w-0 h-0 position-absolute top-0"
        target="_blank"
      />
    </>
  );
};

export default RecordsListing;
