import CustomModal from "components/common/modal";

const NegativeADPExports = ({ open, close, adpExports }) => {
  return (
    <CustomModal
      show={open}
      onHide={() => close()}
      heading="Negative ADP Exports Found"
    >
       <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Co Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">File Number</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Provider</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Reg Earnings</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Reg Hours</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {adpExports?.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.co_code || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.adp_number || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.provider_name || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.reg_earnings || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.reg_hours || ''}</p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
    </CustomModal>
  );
};

export default NegativeADPExports;
