import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {ADP_EXPORT_ENDPOINT, SEND_TO_ADP_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';
import {toast} from 'react-toastify';
import {get, post} from 'services/network';

export const getAdpExportSearch = async (obj, setErrors) => {
  try {
    const result = await get(
      `${ADP_EXPORT_ENDPOINT}/search.csv?start_date=${moment(obj.start_date).format('YYYY-MM-DD')}&end_date=${moment(
        obj.end_date,
      ).format('YYYY-MM-DD')}&payroll_group_id=${obj.payroll_group_id}`,
      true,
    );
    return result;
  } catch (error) {
    console.log('error');
    if (error?.response?.data) {
      setErrors(error?.response?.data.errors);
      return error.response.data
    }
  }
};

export const getAdpExport = async obj => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
  };
  try {
    const result = await get(
      `${ADP_EXPORT_ENDPOINT}?page=${params.page}&per=${params.perPage}&query=${params.search}`,
      true,
    );
    return result;
  } catch (error) {
    console.log('error');
  }
};

export const sendToAdp = async data => {
  try {
    const result = await post(SEND_TO_ADP_ENDPOINT, data, true);
    if (result.meta.status === 200) toast(<CustomToast variant={alertTypes.SUCCESS} message="Mark Paid" />);
    else {
      toast(<CustomToast variant={alertTypes.DANGER} message={result?.meta?.status} />);
    }

    return result;
  } catch (error) {
    console.log(error);
  }
};
